import {openLink} from "./openLink";

export class MapListener {
    constructor(map) {
        this.map = map;
        this.moved = false;
        this.tapped = false;
    }

    mouseDownListener(e) {
        this.moved = false;
    }

    mouseMoveListener(e) {
        this.moved = true;
    }

    mouseUpListener(e) {
        if (!this.moved) {
            openLink(e.target);
        }
    }

    touchEndListener(e) {
        if (!this.tapped) {
            this.tapped = setTimeout(function () {
                this.tapped = null;
                if (!this.moved) {
                    openLink(e.target);
                }
            }, 300);   //wait 300ms then run single click code
        } else {    //tapped within 300ms of last tap. double tap
            clearTimeout(this.tapped); //stop single tap callback
            this.tapped = null;
            const event = new Event('zoom-in')
            this.map.dispatchEvent(event);
            // do nothing, since Hammer JS is handling it in map.js options
        }

        e.preventDefault();
    }
}