import {MapListener} from "./map-listener";

export class Map {
    constructor(elementToAppend) {
        this.map = elementToAppend;
        this.loader = document.querySelector('[data-loader]');
        this.svg = this.map.contentDocument.querySelector('svg');
        this.panels = this.svg.querySelector('g#panels');
        let style = this.svg.querySelector('style');
        if (!style) {
            style = this.map.contentDocument.createElementNS('http://www.w3.org/2000/svg', 'style');
            this.svg.insertBefore(style, this.svg.firstChild);
        }
        let styleContent = style.innerHTML;
        styleContent += '.st0{cursor:pointer;fill:transparent;}';
        styleContent += '.unavailable {fill: #ffd7cc;}';
        styleContent += '.auction {fill: #daffcc;}';
        style.innerHTML = styleContent

        this.defs = this.svg.querySelector('defs');
        if (!this.defs) {
            console.error('PLEASE DEFINE DEFS BEFORE STYLE TAG');
            alert('BRO, THIS IS NOT WORKING!');
        }
    }

    async initialize(cells, soldCells) {
        const self = this;
        this.addListenersToMap();

        this.initializeCells(cells);

        const promises = this.initializeSoldCells(soldCells);

        Promise.any(promises).then(() => {
            self.hideLoader();
            const loadedEvent = new Event('map-loaded');
            self.map.dispatchEvent(loadedEvent);
        });

        Promise.all(promises).then(() => {
            const doneEvent = new Event('map-done')
            self.map.dispatchEvent(doneEvent)
        });
    }

    hideLoader() {
        this.loader.style.display = 'none';
        this.map.style.visibility = 'visible';
        this.map.classList.remove('uk-invisible');
    }

    initializeCells(cells) {
        const self = this;
        cells.forEach(cell => {
            self._initializeCell(cell);
        });
    }

    _initializeCell(cell) {
        const cellId = cell['cell_id'];
        const el = this.svg.querySelector(`[data-cell-id="${cellId}"]`);
        if (!el) {
            console.error('[load-map] unavailable cell not found for:', cellId);
            return;
        }
        if (cell['available'] === false) {
            el.classList.add('unavailable');
        }
        if (cell['auction_running']) {
            el.classList.add('auction')
        }

        const activeAt = document.createAttribute('active-at');
        activeAt.value = cell['active_at_formatted'];
        el.attributes.setNamedItem(activeAt);

        const activeUntil = document.createAttribute('active-until');
        activeUntil.value = cell['active_until_formatted'];
        el.attributes.setNamedItem(activeUntil);

        const price = document.createAttribute('price');
        price.value = cell['price_formatted'];
        el.attributes.setNamedItem(price);

        if (cell['auction_running']) {
            const auction = document.createAttribute('auction');
            auction.value = cell['auction_running'];
            el.attributes.setNamedItem(auction);

            const auctionStart = document.createAttribute('auction-start');
            auctionStart.value = cell['auction_start'];
            el.attributes.setNamedItem(auctionStart);

            const auctionEnd = document.createAttribute('auction-end');
            auctionEnd.value = cell['auction_end'];
            el.attributes.setNamedItem(auctionEnd);
        }
    }

    initializeSoldCells(soldCells) {
        const self = this;

        // set an empty promise into the array to prevent "All promises were rejected" error
        const promises = [new Promise(r => r())];

        soldCells.forEach(soldCell => {
            const promise = self._initializeSoldCell(soldCell);
            promises.push(promise);
        });

        return promises;
    }

    _initializeSoldCell(soldCell) {
        const self = this;
        const cellId = soldCell['cell_id'];
        const el = this.svg.querySelector(`[data-cell-id="${cellId}"]`);
        if (!el) {
            console.error('[load-map] Cell not found for:', soldCell);
            return;
        }

        const pattern = this.map.contentDocument.createElementNS('http://www.w3.org/2000/svg', 'pattern');
        pattern.setAttribute('id', cellId);
        pattern.setAttribute('x', el.attributes['x'].value);
        pattern.setAttribute('y', el.attributes['y'].value);
        pattern.setAttribute('height', el.attributes['height'].value);
        pattern.setAttribute('width', el.attributes['width'].value);
        pattern.setAttribute('patternUnits', 'userSpaceOnUse');

        const image = this.map.contentDocument.createElementNS('http://www.w3.org/2000/svg', 'image');
        image.setAttribute('href', `${window.root()}${soldCell['public_uri']}`);
        image.setAttribute('height', el.attributes['height'].value);
        image.setAttribute('width', el.attributes['width'].value);
        image.setAttribute('cell-id', cellId);
        const promise = new Promise((r, err) => {
            image.addEventListener('load', e => {
                const image = e.target;
                const cellId = image.attributes['cell-id'].value;
                const element = self.svg.querySelector(`[data-cell-id="${cellId}"]`);
                element.style.fill = `url(#${cellId})`;

                const cellSold = document.createAttribute('cell-sold');
                cellSold.value = 'true';
                element.attributes.setNamedItem(cellSold);
                r(cellId);
            });
            image.addEventListener('error', e => {
                const image = e.target;
                const id = image.attributes['cell-id'].value;
                err(id);
            });
        })
        pattern.appendChild(image);
        this.defs.appendChild(pattern);

        return promise;
    }

    addListenersToMap() {
        const allElements = this.panels.querySelectorAll('rect[data-cell-id]');
        const listener = new MapListener(this.map);
        // separately add the eventlistener since the loading of the image blocks it
        allElements.forEach(el => {
            el.addEventListener('mouseup', listener.mouseUpListener);
            el.addEventListener('touchend', listener.touchEndListener, {capture: true});

            el.addEventListener('mousedown', listener.mouseDownListener);
            el.addEventListener('touchstart', listener.mouseDownListener, {passive: true});

            el.addEventListener('mousemove', listener.mouseMoveListener);
            el.addEventListener('touchmove', listener.mouseMoveListener, {passive: true});
        });
    }
}