export function openLink(forElement) {
    const id = forElement.attributes['data-cell-id']?.value;
    const number = forElement.attributes['data-number']?.value;
    const sold = forElement.attributes['cell-sold']?.value;
    const unavailable = forElement.classList.contains('unavailable');
    const auction = forElement.classList.contains('auction');
    if (auction && !sold) {
        const auctionModal = document.querySelector('[data-id=view-auction]');
        auctionModal.classList.remove('uk-hidden');
        const title = auctionModal.querySelector('[data-auction-title-placeholder]');
        title.innerHTML = title.getAttribute('data-auction-title-placeholder').replace('{number}', number);
        auctionModal.addEventListener('hidden', function () {
            auctionModal.classList.add('uk-hidden');
        });

        auctionModal.querySelector('[data-open-id]').setAttribute('value', id);
        auctionModal.querySelector('[data-open]').addEventListener('click', () => {
            const id = auctionModal.querySelector('[data-open-id]').getAttribute('value');
            window.location.href = `${window.root()}/cell/${id}/auction`;
        });

        UIkit.modal(auctionModal).show();
    } else if (unavailable && !sold) {
        const unavailableModal = document.querySelector('[data-id=view-purchase-unavailable]');
        unavailableModal.classList.remove('uk-hidden');
        const title = unavailableModal.querySelector('[data-unavailable-title-placeholder]');
        title.innerHTML = title.getAttribute('data-unavailable-title-placeholder').replace('{number}', number);
        unavailableModal.addEventListener('hidden', function () {
            unavailableModal.classList.add('uk-hidden');
        });
        UIkit.modal(unavailableModal).show();
    } else if (!sold) {
        const modal = document.querySelector('[data-id=view-purchase]');
        modal.classList.remove('uk-hidden');

        const title = modal.querySelector('[data-title-placeholder]');
        title.innerHTML = title.getAttribute('data-title-placeholder').replace('{number}', number);

        const price = forElement.attributes['price']?.value;
        if (price) {
            const text = modal.querySelector('[data-text-placeholder]');
            text.innerHTML = text.getAttribute('data-text-placeholder').replace('{price}', price);
        }

        modal.querySelector('[data-open-id]').setAttribute('value', id);
        modal.querySelector('[data-open]').addEventListener('click', () => {
            const id = modal.querySelector('[data-open-id]').getAttribute('value');
            window.location.href = `${window.root()}/cell/${id}/purchase`;
        });
        modal.addEventListener('hidden', function () {
            modal.classList.add('uk-hidden');
        });
        UIkit.modal(modal).show();
    } else {
        window.location.href = `${window.root()}/cell/${id}`;
    }
}