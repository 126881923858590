import svgPanZoom from 'svg-pan-zoom';
import Hammer from "hammerjs";
import {Map} from "./map";

window.svgPanZoom = svgPanZoom;
window.Hammer = Hammer;

let zoom = null;
const mapElement = document.querySelector('[data-solar-butterfly]');
const intervals = [];
let running = false;
const HTML = "<div style=\"position:fixed;bottom: 0;right: 0; height: 2rem; width: 100vw;text-align: right;padding-right: 1rem\" id=\"fps\"></div>";
// Function that returns a Promise for the FPS
const getFPS = function () {
    return new Promise(resolve => requestAnimationFrame(t1 => requestAnimationFrame(t2 => resolve(1000 / (t2 - t1)))))
};
window.showFPS = function () {
    document.querySelector('.content').insertAdjacentHTML('beforeend', HTML)
    let frameTime = 0;
    let lastLoop = new Date;
    let thisLoop;
    running = true;

    function gameLoop() {
        const thisFrameTime = (thisLoop = new Date) - lastLoop;
        frameTime += (thisFrameTime - frameTime);
        lastLoop = thisLoop;
        if (running) {
            window.requestAnimationFrame(gameLoop);
        }
    }

    window.requestAnimationFrame(gameLoop);

    // Report the fps only every second, to only lightly affect measurements
    let fpsOut = document.getElementById('fps');
    intervals.push(setInterval(() => {
        fpsOut.innerHTML = (1000 / frameTime).toFixed(1) + " FPS";
    }, 1000));
}
window.hideFPS = function () {
    running = false;
    document.querySelector('#fps').remove();
    intervals.forEach(interval => {
        clearInterval(interval);
    });
    intervals.length = 0;
}

// Calling the function to get the FPS
let started = false;
const startMap = function (fps) {
    const mapVersion = '2';
    if (started) {
        // console.log('Stopping, since already started (' + fps + ' FPS)');
        return;
    }
    started = true;
    if (!fps || fps < 30) {
        fps = 30;
    }
    mapElement.addEventListener('load', () => {
        const soldCellsPromise = fetch('/api/cells/sold').then(r => r.json()).then(d => window.sold_cells = d.sold_cells);
        const cellInfoPromise = fetch('/api/cells').then(r => r.json()).then(d => window.cells = d.cells);
        Promise.all([soldCellsPromise, cellInfoPromise])
            .then(() => {
                const map = new Map(mapElement);
                map.initialize(window.cells, window.sold_cells);
            })
    });
    mapElement.addEventListener('map-loaded', () => {

        // Will get called after embed element was loaded
        const options = {
            zoomEnabled: true,
            controlIconsEnabled: false,
            mouseWheelZoomEnabled: true,
            preventMouseEventsDefault: false,
            dblClickZoomEnabled: false,
            zoomScaleSensitivity: 0.6,
            refreshRate: Math.round(fps),
            minZoom: 1,
            maxZoom: 15,
        };
        zoom = svgPanZoom(mapElement, options);
        zoom.resize();
        zoom.fit();
        zoom.center();

        const zoomIn = document.querySelector('[data-zoom-in]');
        const zoomOut = document.querySelector('[data-zoom-out]');
        const search = document.querySelector('[data-search]');
        zoomIn.classList.remove('uk-hidden');
        zoomOut.classList.remove('uk-hidden');
        search.classList.remove('uk-hidden');

        zoomIn.addEventListener('click', e => {
            e.preventDefault();

            zoom.zoomIn();
        });

        zoomOut.addEventListener('click', e => {
            e.preventDefault();

            zoom.zoomOut();
        });

        search.addEventListener('click', e => {
            const hideResults = function (searchModal) {
                const success = searchModal.querySelector('[data-success]');
                if (!success.classList.contains('uk-hidden')) {
                    success.classList.add('uk-hidden')
                }
                const notAvailable = searchModal.querySelector('[data-not-available]');
                if (!notAvailable.classList.contains('uk-hidden')) {
                    notAvailable.classList.add('uk-hidden')
                }
                const notFound = searchModal.querySelector('[data-not-found]');
                if (!notFound.classList.contains('uk-hidden')) {
                    notFound.classList.add('uk-hidden')
                }
            }
            const searchModal = document.querySelector('[data-id=view-search]');
            searchModal.classList.remove('uk-hidden');

            searchModal.querySelector('form').addEventListener('submit', e => {
                e.preventDefault();
                let cellId = parseInt(searchModal.querySelector('input[data-search-input]').value);
                e.target.setAttribute('disabled', '');
                hideResults(searchModal);

                const protocol = window.location.protocol;
                const host = window.location.host;
                const url = `${protocol}//${host}/api/cells/` + cellId;
                fetch(url).then(response => response.json()).then(data => {
                    e.target.removeAttribute('disabled');
                    if (data.found) {
                        if (data.is_available) {
                            const success = searchModal.querySelector('[data-success]');
                            const link = success.querySelector('[data-sponsor-cell]');
                            const purchaseUrl = `${protocol}//${host}/cell/` + data.cell.hash + '/purchase';
                            link.setAttribute('href', purchaseUrl);
                            success.classList.remove('uk-hidden');
                        } else {
                            const notAvailable = searchModal.querySelector('[data-not-available]');
                            const link = notAvailable.querySelector('[data-view-cell]');
                            const purchaseUrl = `${protocol}//${host}/cell/` + data.cell.hash;
                            link.setAttribute('href', purchaseUrl);
                            notAvailable.classList.remove('uk-hidden');
                        }
                    } else {
                        const notFound = searchModal.querySelector('[data-not-found]');
                        notFound.classList.remove('uk-hidden');
                    }
                })
            });

            searchModal.addEventListener('hidden', function () {
                hideResults(searchModal);
                searchModal.classList.add('uk-hidden');
            });

            UIkit.modal(searchModal).show();
        });
    });
};
startMap(60);